<template>
  <div>
    <titleBar
      :title="'Moderation tools'"
      :img="user?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800">
        <b-field horizontal label=" ">
          <header>
            <h2 class="title is-2">
              <span class="icon">
                <!-- 
                <i class="fas fa-edit"></i -->
              </span>
              <span>Moderate</span>
            </h2>
          </header>
        </b-field>
        <hr />

        <!-- START-->

        <b-field horizontal>
          <b-switch v-model="user.betaAccess" type="is-success">
            Beta Access (need to send email manually otherwise, use different UI.)
            <b-tooltip type="is-dark" multilined label="Initial approval">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="user.betaCreator" type="is-warning">
            Creator (beta invited)
            <b-tooltip type="is-dark" multilined label="Initial approval">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="user.betaImgGen" type="is-success">
            Studio access (beta img gen)
            <b-tooltip type="is-dark" multilined label="Initial approval">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="user.supporterBadge" type="is-success">
            Supporter badge
            <b-tooltip type="is-dark" multilined label="Initial approval">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="user.verifiedbadge" type="is-info">
            Verified badge
            <b-tooltip type="is-dark" multilined label="Display a blue tick next to your page name in listings.">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
            <span class="tag is-warning is-light">Premium</span>
          </b-switch>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="user.featured" type="is-dark">
            Featured badge
            <b-tooltip type="is-dark" multilined label="">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="user.blocked" type="is-warning">
            Block account (only can't make changes to settings anymore, like re-enable it)
            <b-tooltip type="is-dark" multilined label="">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="user.userDisabled" type="is-danger">
            User disabled account
            <b-tooltip type="is-dark" multilined label="bye">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal label="invitedBy">
          <b-input size="is-" placeholder="username" v-model="user.invitedBy"></b-input>
        </b-field>

        <b-field horizontal label="handle">
          <b-input size="is-" placeholder="username" v-model="user.handle"></b-input>
        </b-field>
        TODO: use a custom path renamer method that updates the references in all users (follows.)

        <b-field horizontal label="Bio">
          <b-input
            type="textarea"
            placeholder="Hi!  I chat and I make bots."
            v-model="user.bio"
            :loading="posting"
            :disabled="posting"
          ></b-input>
        </b-field>

        <!-- END     
    -->
        <b-field horizontal label="   ">
          <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
            >Save changes</b-button
          >
        </b-field>

        <hr />
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style></style>
